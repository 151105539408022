.main-section {
  background-color: transparent;  
  background-image: radial-gradient(at top right, var(--e-global-color-a53e99b) 0%, var(--e-global-color-secondary) 80%);
}

.back-to-top {
  position: fixed ;
  z-index: 100;
  right: 0;
  background-color: var(--e-global-color-accent);
  color: #fff;
  transition: .3s;
  font-size: 1.2rem;
  border: none;
  bottom: -100px;
  margin: 30px;
  width: 50px;
  right: 0px;
  height: 50px;
  border-radius: 10px;
}

.show-back-to-top {
  bottom: 0;
}