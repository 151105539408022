.banner-home {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  overflow: hidden;
  padding-top: 200px;
}

.banner-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  margin: 0 20px;
}

.tag-content-banner {
  display: flex;
  background-image: radial-gradient(at top left, #206EF51F 0%, #A2A2A217 100%);
  align-items: baseline;
  border: 1px solid;
  padding: 5px 15px;
  border-radius: 30px;
  font-size: .9rem;
  border-color: var(--e-global-color-21b3afa);
  cursor: pointer;
  padding-left: 15px;
  transition: .3s;
}

.tag-content-banner:hover {
  border-color: var(--e-global-color-accent);
}

.tag-content-banner > span {
  margin: -1px 9px 0 -4px;
  padding: 0 9px 0 0;
  border-style: solid;
  border-width: 0 1px 0 0;
  color: var(--e-global-color-primary);
  border-color: #ffffff30;
  font-size: .85rem;
}

.tag-content-banner > span > i {
  font-size: .8rem;
  color: #FFD700;
}

.tag-content-banner > p {
  font-size: .85rem;
  word-spacing: 1px;
  color: var(--e-global-color-text);
  border-style: none;
  font-weight: 200;
  letter-spacing: .2px;
  display: flex;
  gap: 10px;
}

.banner-content-text {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}


.left-banner-text {
  width: 90% !important;
}

.left-banner-text > h1 {
  font-size: 3.3rem;
  display: flex;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  display: inline;
  font-weight: 450;
  line-height: 95px;
  word-spacing: -2px;
  color: #fff;
}

.right-banner-text {
  padding-top: 10px;
  width: 60%;
  display: flex;
  gap: 25px;
  flex-direction: column;
}

.right-banner-text > p {
  color: var(--e-global-color-text);
  width: 100%;
  font-size: 1.05rem;
  letter-spacing: .2px;
  word-spacing: 0px;
  line-height: 30px;
  font-weight: 250;
  max-width: 100%;
}

.dashboard-image-banner {
  width: 95%;
  margin-top: 50px;
  transition: .5s;
  padding: 8px;
  cursor: pointer;
  z-index: 100;
  background-color: #ffffff0d;
  border-style: solid;
  border-width: 1px;
  border-color: #ffffff30;
  border-radius: 25px 25px 25px 25px;
  box-shadow: 0 20px 80px -45px rgba(32.00000000000001, 110.0000000000001, 245, .7);
  --e-transform-rotateX: 1deg;
  --e-transform-perspective: 127px;
  transform: perspective(var(--e-transform-perspective, 0)) rotate(var(--e-transform-rotateZ, 0)) rotateX(var(--e-transform-rotateX, 0)) rotateY(var(--e-transform-rotateY, 0)) translate(var(--e-transform-translate, 0)) translateX(var(--e-transform-translateX, 0)) translateY(var(--e-transform-translateY, 0)) scaleX(calc(var(--e-transform-flipX, 1)* var(--e-transform-scaleX, var(--e-transform-scale, 1)))) scaleY(calc(var(--e-transform-flipY, 1)* var(--e-transform-scaleY, var(--e-transform-scale, 1)))) skewX(var(--e-transform-skewX, 0)) skewY(var(--e-transform-skewY, 0));
}

.dashboard-image-banner > img {
  width: 100%;
  object-fit: contain;
  z-index: 110;
  border-radius: 20px 20px 20px 20px;
  vertical-align: middle;
  display: inline-block;
  height: auto;
  max-width: 100%;
  border: none;
  box-shadow: none;
}

.dashboard-image-banner:hover {
  --e-transform-rotateX: 0deg;
  --e-transform-perspective: 127px;
}

.center-db {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.background-shape {
  width: 600px;
  position: absolute;
}

.shape-right {
  right: -100px;
  top: 300px;
  opacity: .6;
  filter: brightness(100%) contrast(100%) saturate(100%) blur(0px) hue-rotate(315deg);
}

.shape-left {
  opacity: .5;
  filter: brightness(100%) contrast(100%) saturate(100%) blur(0px) hue-rotate(315deg);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .5);
  left: -100px;
  top: 100px;
}

.company-logo-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 100px;
  justify-content: center;
}

.company-logo {
  display: flex;
  gap: 60px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.header-text-company {
  font-size: 1rem;
  color: var(--e-global-color-text);
  font-weight: 200;
}

.header-text-company > span {
  font-weight: 400;
  color: #fff;
}

.contact-section-banner {
  display: flex;
  align-items: center;
  gap: 15px;
}

.contact-section-banner > i {
  background-color: #fff;
  padding: 9px  15px;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
	transform: scale(1);
	animation: pulse 2s infinite;
  font-size: 1.3rem;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 13px rgba(255, 255, 255, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}

.contact-section-banner > div {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.contact-section-banner > div > h3 {
  color: var(--e-global-color-text);
  font-size: .85rem;
  font-weight: 300;
}

.contact-section-banner > div > p {
  color: #fff;
  font-size: .9rem;
  font-weight: 300;
}

.start-contact-banner {
  display: flex;
  gap: 25px;
}

.logo-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
  gap: 100px;
}

.logo-list > li {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.logo-list > li > img {
  width: 190px;
  height: 90px;
  filter: brightness(0) invert(.5);
  object-fit: contain;
}