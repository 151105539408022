footer {
  padding: 130px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-container {
  display: flex;
  flex-direction: column;
}

.top-box-logo-bio {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* gap: 100px; */
  border-bottom: 1px solid var(--e-global-color-9fb62f4);
  /* padding-bottom: 50px; */
}

.top-box-logo-bio > p {
  font-size: 1rem;
  color: var(--e-global-color-text);
  max-width: 55%;
  font-weight: 200;
}

.copy-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.copy-logo > p {
  font-size: .8rem;
  font-weight: 200;
  color: var(--e-global-color-text);
}

.footer-bottom {
  width: 100%;
  display: flex;
  gap: 200px;
  margin-top: 60px;
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-info > div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contact-info > div > h3 {
  color: #fff;
  font-weight: 400;
  font-size: .95rem;
}

.contact-info > div > p {
  font-size: .8rem;
  color: var(--e-global-color-text);
  max-width: 90%;
  line-height: 23px;
  font-weight: 200;
}

.quick-link-filter {
  display: flex;
  width: 100%;
  padding-right: 80px;
  justify-content: space-between;
}

.quick-link-filter > div {
  display: flex;
  gap: 15px;
  flex-direction: column;
  width: 100%;
}

.quick-link-filter > div > h3 {
  color: #fff;
  font-weight: 400;
  font-size: .95rem;
}

.quick-link-filter > div > ul {
  display: flex;
  flex-direction: column;
  width: 100%;
  list-style-type: none;
  gap: 15px;
}


.quick-link-filter > div > ul > li {
  font-size: .85rem;
  color: var(--e-global-color-text);
  max-width: 80%;
  cursor: pointer;
  line-height: 23px;
  font-weight: 200;
}

.bold-text {
  color: #fff;
  font-weight: 300;
}

.social-media {
  border: 1px solid var(--e-global-color-9fb62f4);
  margin-top: 100px;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.social-media > ul {
  display: flex;
  border: 1px solid var(--e-global-color-9fb62f4);
  width: 200px;
}
