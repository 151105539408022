.contact-container {
  padding-top: 200px;
  box-shadow: 0 150px 100px -100px rgba(32.00000000000001, 110.0000000000001, 245, .15);
  border-radius: 0 0 150px 150px;
  padding-bottom: 100px;
}

.contact-header-wrapper {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  color: #fff;
  gap: 0px;
}

.header-section-contact-page {
  display: flex;
  align-items: center;
  gap: 60px;
  flex-direction: column;
  justify-content: space-between;
}

.contact-header-wrapper > h1 {
  font-size: 2.5rem;
  font-weight: 400;
}

.contact-header-wrapper > p {
  color: var(--e-global-color-text);
  font-weight: 200;
  font-size: 1rem;
  margin-top: 20px;
  max-width: 800px;
  line-height: 23px;
}


.contact-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  border: 1px solid var(--e-global-color-9fb62f4);
  padding: 30px;
  padding-bottom: 35px;
  max-width: 600px;
  width: 100%;
  border-radius: 10px;
  color: #fff;
}

.contact-form > h2 {
  font-weight: 300;
}

.contact-form > div {
  display: flex;
  justify-content: space-between;
}

.contact-form > input {
  border-radius: 5px;
  display: block;
  font-family: inherit;
  font-size: 13px;
  width: 100%;
} 

.contact-form > input > input {
  height: 40px;
  padding: 7px 15px;
}

.col-1-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}


.col-1-input > textarea {
  height: 200px;
  padding: 10px 15px;
  resize: none;
}

.col-1-input>input, .col-1-input>textarea {
  border: 1px solid #10182833;
  border-radius: 5px;
  display: block;
  font-family: inherit;
  font-size: 13px;
  outline: none;
  width: 100%;
  border-style: solid;
  color: var(--e-global-color-primary);
  border-width: 1px;
  padding: 16px 25px 16px 20px;
  border-radius: 10px;
  border-color: var(--e-global-color-9fb62f4);
  background-color: var(--e-global-color-a3b8a01);
}

.col-1-input>input::placeholder, .col-1-input>textarea::placeholder {
  font-size: 1rem;
  font-family: inherit;
  font-weight: 300;
}

.col-2-container {
  display: flex;
  gap: 30px;
  width: 100%;
}

.form-and-contact-list {
  display: flex;
  gap: 40px;
}

.contact-info-list {
  margin-top: 40px;
}

.contact-info-list > ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-info-list > ul > li {
  display: flex;
  gap: 20px;
}

.contact-info-list > ul > li > i {
  border: 1px solid var(--e-global-color-accent);
  margin-left: 30px;
  font-size: 1.3rem;
  margin-bottom: 40px;
  padding: 15px 20px;
  background-color: var(--e-global-color-1a5762f);
  border-radius: 50%;
  transition: .3s;
  color: #fff;
}

.contact-info-list > ul > li > div > h3 {
  color: #fff;
  font-size: 1.3rem;
  font-weight: 300;
}

.contact-info-list > ul > li > div > p {
  color: var(--e-global-color-text);
  font-weight: 200;
  font-size: .95rem;
  max-width: 70%;
  margin-top: 10px;
  line-height: 25px;
}