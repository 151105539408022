.button-standard {
  padding: 12px 25px;
  font-family: var(--e-global-typography-8104a53-font-family), Sans-serif;
  font-size: .98rem;
  color: var(--e-global-color-primary);
  background-color: var(--e-global-color-204f3c1);
  border-style: solid;
  border-width: 1px;
  border-color: #ffffff40;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: .3s;
  border-radius: 10px 10px 10px 10px;
}

.button-standard:hover {
  scale: .9;
}


.button-blue {
  padding: 15px 35px;
  font-size: 1.05rem;
  letter-spacing: 0px;
  color: var(--e-global-color-primary);
  background-color: var(--e-global-color-accent);
  border-style: none;
  border-radius: 10px;
  transition: .3s;
}

.button-blue:hover {
  background-color: var(--e-global-color-8dcc563);
  color: var(--e-global-color-secondary);
  scale: .95;
}