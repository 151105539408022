:root {
  --e-global-color-primary: #FFFFFF;
  --e-global-color-secondary: #070315;
  --e-global-color-text: #FFFFFF80;
  --e-global-color-accent: #206EF5;
  --e-global-color-1a5762f: #0C2042;
  --e-global-color-204f3c1: #206EF51F;
  --e-global-color-8dcc563: #D0D0D7;
  --e-global-color-8364601: #8F8F8F;
  --e-global-color-a53e99b: #082169;
  --e-global-color-3610e31: #110D1B;
  --e-global-color-21b3afa: #206EF569;
  --e-global-color-9fb62f4: #FFFFFF30;
  --e-global-color-a3b8a01: #FFFFFF14;
}

html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: var(--e-global-color-secondary);
  font-family: "Poppins", sans-serif;
  height: 100vh;
}

::placeholder {
  font-family: "TTFont";
}

.mb-3 {
  margin-bottom: 1rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: .5rem;
}

button {
  cursor: pointer !important;
}


input:focus{
  outline: none;
}


.content-sizing {
  max-width: 1260px;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 20px;
}

.magic-text {
  margin: 0 8px 0 1px;
  padding: 2px 10px;
  background-color: transparent;
  background-image: linear-gradient(91deg, var(--e-global-color-1a5762f) 0%, #4F45F800 100%);
  border-style: solid;
  border-width: 0 0 0 3px;
  border-color: var(--e-global-color-accent);
}

.highlight-white {
  color: #FFFFFF;
  font-weight: 350;
}