.main-error-box {
    color: white;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    height: 100vh;
    width: 100%;
    gap: 25px;
}

.error-box-one {
    font-size: 150px;
    font-weight: 400;
    color: var(--e-global-color-text);
}

.error-box-two {
    display: flex;
    flex-direction: column;
}

.error-box-two-h2 {
    align-items: flex-start;
    color: #fff;
    font-size: 2.5rem;
    font-weight: 400;
    gap: 10px;
}

.error-box-two > p {
    color: var(--e-global-color-text);
    font-weight: 200;
    font-size: .95rem;
    padding-top: 15px;
}

.error-box-two > div {
    display: flex;
    padding-top: 55px;
}

