header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--e-global-color-9fb62f4);
  justify-content: center;
  position: fixed !important;
  transition: all 0.3s ease 0s;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.scroll-back {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background: var(--e-global-color-a53e99b);
}

.contact-button > a{
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
}
.logo-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.icon-box-logo {
  background-color: #fff;
  padding: 9px 8px 8px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.header-wrapper {
  justify-content: space-between;
  padding: 10px 0;
}

nav > ul {
  display: flex;
  list-style-type: none;
  gap: 20px;
  padding: 20px 0;
}

nav > ul > li {
  padding: 5px 0;
}

nav > ul > li > a {
  transition: .3s;
  padding: 6px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: .9rem;
  text-decoration: none;
  font-weight: 300;
  color: var(--e-global-color-text);
}

nav > ul > li > a:hover {
  color: var(--e-global-color-primary);
  background-color: var(--e-global-color-204f3c1);
  border-style: none;
}

.active-menu-itm {
  color: var(--e-global-color-primary);
  background-color: var(--e-global-color-204f3c1);
  border-style: none;
}

.menu-button-header {
  padding: 10px;
  margin: 15px 0;
  border: none;
  border-radius: 8px;
  display: none;
  color: #fff;
  font-size: 1.4rem;
  background-color: var(--e-global-color-accent);
}

@media only screen and (max-width: 1025px)  {
  nav {
    display: none !important;
  }

  .talk-header-button {
    display: none !important;
  }

  .menu-button-header {
    display: flex;
  }
}