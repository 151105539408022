.page-container {
  background-color: #000;  
  box-shadow: 0 -100px 100px -85px rgba(32.00000000000001, 110.0000000000001, 245, .35);
  border-radius: 80px;
  padding-top: 150px;
}

.sass-feature {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.sass-feature-content {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.header-section-component {
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-section-component > h2 {
  font-size: 2.5rem;
  text-align: center;
  max-width: 800px;
  font-weight: 400;
}

.header-section-component > p {
  margin-top: 20px;
  font-weight: 100;
  color: var(--e-global-color-text);
}

.list-sass-feature {
  width: 100%;
  display: flex;
  margin-top: 80px;
  gap: 50px;
  margin-bottom: 80px;
  justify-content: space-between;
}

.list-sass-feature > li {
  width: 33.3%;
}

.single-card-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.single-card-box > h3 {
  font-weight: 400;
  font-size: 1.2rem;
  color: #fff;
}

.single-card-box > p {
  color: var(--e-global-color-text);
  font-weight: 200;
  font-size: .9rem;
  max-width: 90%;
  margin-top: 10px;
}

.image-box-content-card {
  background-image: linear-gradient(180deg, #110D1B 10%, #000 90%);
  padding: 20px;
  width: 100%;
  margin-bottom: 30px;
  border-style: solid;
  border-width: 1px 0 0;
  border-block-start-width: 1px;
  border-inline-end-width: 0px;
  border-block-end-width: 0px;
  border-inline-start-width: 0px;
  border-color: #ffffff1f;
  border-radius: 20px 20px 0px 0px;
  padding-block-start: 30px;
  padding-block-end: 30px;
  padding-inline-start: 30px;
  padding-inline-end: 30px;
  transition: .3s;
  cursor: pointer;
}

.image-box-content-card > img {
  width: 100%;
  object-fit: contain;
}

.image-box-content-card:hover {
  box-shadow: 0 -17px 30px -30px rgba(32.00000000000001, 110.0000000000001, 245, 1);
}

.benefits-list-wrapper {
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
}

.benefits-list {
  display: flex;
  padding: 40px 0;
  align-items: center;
  justify-content: center;
  padding-bottom: 90px;
}

.left-side-content {
  width: 45% !important;
}

.left-side-benefits-header {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.left-side-benefits-header > h2 {
  color: #fff;
  font-size: 2.4rem;
  font-weight: 400;
}

.left-side-benefits-header > p {
  color: var(--e-global-color-text);
  font-weight: 200;
  font-size: .95rem;
  max-width: 800px;
  margin-top: 20px;
  line-height: 25px;
}

.video-main

.video-one {
  margin-right: -105px;
}

.video-two {
  margin-left: -95px;
}

.list-of-benefits {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  margin-top: 50px;
}

.single-of-benefits {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.single-of-benefits > i {
  border: 1px solid var(--e-global-color-accent);
  padding: 15px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: .3s;
  display: flex;
  background-color: var(--e-global-color-1a5762f);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  color: #fff;
}

.single-of-benefits > i:hover {
  background-color: var(--e-global-color-accent);
}

.single-of-benefits > h3 {
  color: #fff;
  font-weight: 300;
}

.single-of-benefits > p {
  color: var(--e-global-color-text);
  font-weight: 200;
  font-size: .95rem;
  max-width: 90%;
  margin-top: 10px;
  line-height: 25px;
}

.innovation-list {
  border: 1px solid;
  margin: 40px 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 60px;
}

.innovation-list > li {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 200;
  gap: 10px;
}

.innovation-list > li > i {
  border-radius: 50%;
  font-size: .8rem;
  padding: 1px 4px;
  border: 1px solid var(--e-global-color-accent);
}

.list-of-services {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  padding-bottom: 100px;
}

.services-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.service-section-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;

}

.service-section-header > h2 {
  color: #fff;
  font-size: 2.4rem;
  font-weight: 400;
  max-width: 500px;
  text-align: center;
}

.service-section-header > h2 > span {margin-left: 8px;}



.service-section-header > p {
  max-width: 900px;
  color: var(--e-global-color-text);
  font-weight: 200;
  font-size: .95rem;
  text-align: center;
  margin-top: 20px;
  line-height: 25px;
}

.list-service-ul {
  border: 1px solid var(--e-global-color-9fb62f4);
  width: 100%;
  margin-top: 60px;
  border-radius: 20px;
  display: flex;
  flex-wrap: wrap;
  
}

.list-service-ul > li {
  width: 25%;
  padding: 35px 0;
  display: flex;
  text-align: left;
  align-items: flex-start;
  flex-direction: column;
  cursor: pointer;
  transition: .3s all;
  justify-content: flex-start;
  border-right: 1px solid var(--e-global-color-9fb62f4);
  border-bottom: 1px solid var(--e-global-color-9fb62f4);
  padding-right: 20px;
}

.list-service-ul > li:hover {
  background: radial-gradient(at bottom left, var(--e-global-color-1a5762f) 0%, #000 68%);
}
.list-service-ul > li:nth-child(4), .list-service-ul > li:nth-child(8), .list-service-ul > li:nth-child(12)  {
  border-right: none;
}

.list-service-ul > li:nth-child(9), .list-service-ul > li:nth-child(10), .list-service-ul > li:nth-child(11), .list-service-ul > li:nth-child(12)  {
  border-bottom: none;
}


.list-service-ul > li:nth-child(1){
  border-radius: 20px 0 0 0;
}


.list-service-ul > li:nth-child(4){
  border-radius: 0 20px 0 0;
}

.list-service-ul > li:nth-child(9){
  border-radius: 0 0 0 20px;
}

.list-service-ul > li:nth-child(12){
  border-radius: 0 0 20px 0;  
}

.list-service-ul > li > h3 {
  color: #fff;
  border-left: 2.5px solid var(--e-global-color-accent);
  padding-left: 30px;
  border-radius: 1px;
  font-weight: 300;
  margin-left: -1.5px;
}

.list-service-ul > li > p {
  color: var(--e-global-color-text);
  font-weight: 200;
  font-size: .9rem;
  margin-top: 20px;
  padding-left: 30px;
  line-height: 25px;
}

.list-service-ul > li > i {
  border: 1px solid var(--e-global-color-accent);
  margin-left: 30px;
  margin-bottom: 40px;
  padding: 15px 18px;
  background-color: var(--e-global-color-1a5762f);
  border-radius: 50%;
  transition: .3s;
  color: #fff;
}

.center-content {
  justify-content: center !important;
  background: radial-gradient(at bottom left, var(--e-global-color-1a5762f) 0%, #000 68%);
}

.list-service-ul > li > i:hover {
  background-color: var(--e-global-color-accent);
}


.user-rating {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 120px;
}

.user-rating-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.list-of-review {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: minmax(90px, auto);
  gap: 40px;
  align-items: flex-start;
  justify-content: space-between;
}

.list-of-review > li {
  cursor: pointer;
  transition: .3s;
  background-image: radial-gradient(at top left, var(--e-global-color-3610e31) 0%, #000 100%);
  padding: 30px;
  border-radius: 12px 12px 12px 12px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, .5);
  border: 1px solid var(--e-global-color-9fb62f4);
}

.list-of-review > li:hover {
  transform: translateY(-5px);
  box-shadow: 0 80px 100px -85px rgba(32.00000000000001,110.0000000000001,245,.35);
}

.list-of-review > li > p {
  color: var(--e-global-color-text);
  font-weight: 200;
  font-size: .8rem;
  margin-top: 20px;
  line-height: 23px;
}

.profile-header-review {
  display: flex;
  flex-direction: column;
}

.profile-header-text {
  display: flex;
  flex-direction: column;
  gap: -10px;
}

.profile-header-text > h3 {
  color: #fff;
  font-size: 1rem;
  font-weight: 300;
}

.profile-header-text > p {
  color: var(--e-global-color-text);
  font-weight: 200;
  font-size: .8rem;
  line-height: 20px;
}

.full-width {
  width: 100%;
  max-width: 100% !important;
}

.faq-main-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 120px;
}

.faq-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.faq-list-container-main-page {
  display: flex;
  flex-direction: column;
  margin: 50px 0 30px 0;
  width: 100%;
  border: 1px solid var(--e-global-color-9fb62f4);
  overflow: hidden;
  border-radius: 30px;
  list-style-type: none;
  max-width: 700px;
}

.faq-list-container-main-page > li:first-child {
  border: none;
}

.faq {
  width: 100%;
  border-top: 1px solid var(--e-global-color-9fb62f4);
  padding: 20px 30px;
  border-radius: 30px;
  cursor: pointer;
}

.question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.question > h3 {
  font-size: 1rem;
  font-weight: 300;
  color: #fff;
}

.question > i {
  color: var(--e-global-color-text);
  font-size: 1.2rem;
  transition: .3s;
}

.answer {
 max-height: 0; 
 overflow: hidden;
 transition: max-height .6s ease;
}

.answer p {
  padding-top: 1rem;
  line-height: 1.6rem;
  font-size: .8rem;
  font-weight: 200;
  color: var(--e-global-color-text);
}

.faq.active .answer{
  max-height: 300px;
  animation: fade .6s ease-in-out;
}

.faq.active > .question > i{
  transform: rotate(180deg);
  transition: .3s;
}

@keyframes fade{
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.faq-child-contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
}

.faq-child-contact-info > h3 {
  font-weight: 400;
  font-size: 1.5rem;
}

.faq-question {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-items: center;
}


.faq-question > h3 {
  color: #fff;
  font-weight: 300;
}

.faq-question > p {
  margin-bottom: 25px;
  margin-top: 5px;
  font-weight: 300;
  color: var(--e-global-color-text);
}

.book-meeting {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 80px;
  
}

.book-meeting-container {
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  align-items: flex-end;
  border: 1px solid var(--e-global-color-9fb62f4);
  box-shadow: 0 -17px 30px -30px rgba(32.00000000000001,110.0000000000001,245,1)
}

.book-meeting-container  img {
  margin-bottom: -10px;
}

.left-side-book-meeting {
  display: flex;
  flex-direction: column;
  padding: 90px 80px;
  max-width: 700px;
  align-items: flex-start;
  gap: 30px;
}

.left-side-book-meeting > h1 {
  color: #fff;
  font-weight: 300;
  font-size: 2.5rem;
}

.left-side-book-meeting > p {
  font-weight: 200;
  color: var(--e-global-color-text);
  line-height: 28px;
}


@media only screen and (max-width: 1075px)  {
  .logo-list {
    display: grid !important;
    flex-wrap: wrap;
    grid-template-columns: 1fr 1fr !important;
  }

  .logo-list > li {
    width: 100%;
  }

  .logo-list > li > img {
    width: 200% !important;
  }
}



@media only screen and (max-width: 1025px)  {
  .banner-wrapper {
    padding: 0 20px;
  }
  .banner-content-text {
    flex-direction: column;
  }

  .banner-content-text > div {
    width: 100%;
  }

  .benefits-list-wrapper {
    flex-direction: column;
  }

  .benefits-list-wrapper > div {
    width: 90% !important;
  }

  .video-main {
    width: 100%;
    margin: 0;
  }

  .reverse-flex {
    flex-direction: column-reverse;
  }

  .list-service-ul > li {
    width: 50% !important;
  }

  .list-of-review {
    grid-template-columns: 1fr 1fr !important;
  }

  .book-meeting-container {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .book-meeting-container img {
    display: none;
  }
}


@media only screen and (max-width: 765px)  {

  .sass-feature-content {
    align-items: center !important;
  }

  .list-sass-feature {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .list-sass-feature > li {
    width: 80% !important;
  }
}

@media only screen and (max-width: 700px)  { 
  .list-service-ul {
    overflow: hidden;
  }
  .list-service-ul > li {
    width: 100% !important;
    border: none;
    border-radius: 0 !important;
    border-bottom: 1px solid var(--e-global-color-9fb62f4) !important;
  }

  .list-of-review {
    grid-template-columns: 1fr !important;
  }
}