.about-us-container {
  padding-top: 200px;
  padding-bottom: 100px;
}

.about-header-wrapper {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  color: #fff;
  gap: 0px;
}

.header-section-about-page {
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-header-wrapper > h1 {
  font-size: 2.5rem;
  font-weight: 400;
}

.about-header-wrapper > p {
  color: var(--e-global-color-text);
  font-weight: 200;
  font-size: 1rem;
  margin-top: 20px;
  max-width: 800px;
  line-height: 23px;
}

.about-info-container {
  background: #000;  
  display: flex;
  border-radius: 80px;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  flex-direction: column;
  gap: 100px;
  padding-bottom: 0;
}

.about-info-container-section {
  display: flex;
  color: #fff;
  flex-direction: column;
  gap: 30px;
  max-width: 545px;
}

.about-info-container-section > h2 {
  font-weight: 300;
  font-size: 2rem;
  margin-bottom: 10px;
}

.about-paragraph-page {
  display: flex;
  flex-direction: column;
  gap: 30px;
  border-bottom: 1px solid var(--e-global-color-9fb62f4);
  padding-bottom: 30px;
}

.about-paragraph-page > p {
  color: var(--e-global-color-text);
  font-weight: 200;
  text-align: left;
  font-size: .9rem;
  max-width: 800px;
  line-height: 25px;
}

.single-about-para {
  border-bottom: 1px solid var(--e-global-color-9fb62f4);
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.single-about-para > h3 {
  font-weight: 300;
}

.single-about-para > p {
  color: var(--e-global-color-text);
  font-weight: 200;
  text-align: left;
  font-size: .9rem;
  max-width: 800px;
  line-height: 25px;
}

.about-stats {
  display: flex;
  width: 100%;
  list-style-type: none;
  justify-content: space-between;
}

.about-stats > li {
  gap: 5px;
  display: flex;
  flex-direction: column;
}

.about-stats > li > p {
  font-weight: 200;
  font-size: 1rem;
  color: var(--e-global-color-text);
}

.about-stats > li > h4 {
  font-size: 2.3rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 400;
  gap: 2px;
}

.about-stats > li > h4 > span {
  font-size: 1.3rem;
  font-weight: 300;
  color: var(--e-global-color-accent);
}